import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="bg-gray-800 text-white py-12 content">
            <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-3 gap-8">
                <div>
                    <h3 className="text-xl mb-4">{t('footer.devIdea')}</h3>
                    <p>{t('footer.description')}</p>
                </div>
                <div className="border-l border-gray-600 pl-8">
                    <h3 className="text-xl mb-4">{t('footer.quickLinks')}</h3>
                    <ul className="text-gray-400">
                        <li><a href="#home" className="block mb-2 hover:text-gray-300">{t('footer.home')}</a></li>
                        <li><a href="#services" className="block mb-2 hover:text-gray-300">{t('footer.services')}</a></li>
                        <li><a href="#process" className="block mb-2 hover:text-gray-300">{t('footer.process')}</a></li>
                        <li><a href="#about" className="block mb-2 hover:text-gray-300">{t('footer.about')}</a></li>
                        <li><a href="#references" className="block mb-2 hover:text-gray-300">{t('footer.references')}</a></li>
                        <li><a href="#contact" className="block mb-2 hover:text-gray-300">{t('footer.contact')}</a></li>
                    </ul>
                </div>
                <div className="border-l border-gray-600 pl-8">
                    <h3 className="text-xl mb-4">{t('footer.contactInfo')}</h3>
                    <p>{t('footer.address')}</p>
                    <p>{t('footer.city')}</p>
                    <p>{t('footer.phone')}</p>
                    <p>{t('footer.email')}</p>
                </div>
            </div>
            <div className="text-center mt-12 border-t border-gray-600 pt-8">
                <p className="text-sm text-gray-400">
                    {t('footer.copyright')} | <a href="#" className="hover:text-gray-300">{t('footer.privacyPolicy')}</a> | <a href="#" className="hover:text-gray-300">{t('footer.termsOfService')}</a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
