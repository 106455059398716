import React from 'react';
import { useTranslation } from 'react-i18next';
import Timeline from './Timeline';

const References = () => {
    const { t } = useTranslation();
    const stages = ['Consultation', 'Tender', 'Oversight', 'Signoff'];

    return (
        <section id="references" className="bg-purple-900 py-16 text-white content-container">
            <h2 className="text-2xl text-center mb-8">{t('references.title')}</h2>
            <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="bg-purple-700 p-4 rounded-lg shadow text-center">
                    <a href="https://realview.hu/" target="_blank" rel="noreferrer">
                        <img src="/images/realview.png" alt={t('references.reference1.alt')}
                             className="mb-4 w-full h-40 object-cover rounded-lg cursor-pointer" />
                        <h3 className="text-xl">{t('references.reference1.title')}</h3>
                    </a>
                    <p className="mb-2">{t('references.reference1.description')}</p>
                    <Timeline stages={stages} start={0} end={3} text={t('references.reference1.stages')} />
                </div>
                <div className="bg-purple-700 p-4 rounded-lg shadow text-center">
                    <a href="http://safie.me/" target="_blank" rel="noreferrer">
                        <img src="/images/safie.png" alt={t('references.reference2.alt')} className="mb-4 w-full h-40 object-cover rounded-lg cursor-pointer" />
                        <h3 className="text-xl">{t('references.reference2.title')}</h3>
                    </a>
                    <p className="mb-2">{t('references.reference2.description')}</p>
                    <Timeline stages={stages} start={2} end={3} text={t('references.reference2.stages')} />
                </div>
                <div className="bg-purple-700 p-4 rounded-lg shadow text-center">
                    <a href="https://www.aquashieldcontrol.com/" target="_blank" rel="noreferrer">
                        <img src="/images/auqashield.png" alt={t('references.reference3.alt')}
                             className="mb-4 w-full h-40 object-cover rounded-lg cursor-pointer" />
                        <h3 className="text-xl">{t('references.reference3.title')}</h3>
                    </a>
                    <p className="mb-2">{t('references.reference3.description')}</p>
                    <Timeline stages={stages} start={1} end={3} text={t('references.reference3.stages')} />
                </div>
            </div>
        </section>
    );
};

export default References;
