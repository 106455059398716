import React from 'react';

const Timeline = ({ stages, start, end, text}) => {
    return (
        <div className="w-full pt-4">
            <div className="flex justify-between items-center">
                {stages.map((stage, index) => (
                    <React.Fragment key={index}>
                        <div
                            className={`relative ${
                                index >= start && index <= end ? 'bg-white' : 'bg-purple-500'
                            } w-1/4 h-1`}
                        >
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <div className="flex justify-center items-center mt-1">
                <div className="text-center">
                    <div
                        className={`p-1 rounded-full border-2ó border-white`}
                    >
                        {text}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Timeline;