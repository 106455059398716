import React from 'react';
import { useTranslation } from 'react-i18next';

const Process = () => {
    const { t } = useTranslation();

    return (
        <section id="process" className="bg-purple-900 py-12 content-container">
            <h2 className="text-2xl text-center text-white mb-8">{t('process.title')}</h2>
            <ol className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                <li                 className="bg-white border border-purple-500 p-4 rounded-lg shadow text-center font-normal text-lg flex flex-col items-center space-y-4">
                    <span className="bg-purple-500 p-4 rounded-full text-white w-20 h-20 flex items-center justify-center shadow-md">
                        <i className="fas fa-comments fa-lg"></i>
                    </span>
                    <span className="h-12">{t('process.step1.title')}</span>
                    <p className="text-sm">{t('process.step1.description')}</p>
                </li>
                <li className="bg-white border border-purple-500 p-4 rounded-lg shadow text-center font-normal text-lg flex flex-col items-center space-y-4">
                    <span className="bg-purple-500 p-4 rounded-full text-white w-20 h-20 flex items-center justify-center shadow-md">
                        <i className="fas fa-file-contract fa-lg"></i>
                    </span>
                    <span className="h-12">{t('process.step2.title')}</span>
                    <p className="text-sm">{t('process.step2.description')}</p>
                </li>
                <li className="bg-white border border-purple-500 p-4 rounded-lg shadow text-center font-normal text-lg flex flex-col items-center space-y-4">
                    <span className="bg-purple-500 p-4 rounded-full text-white w-20 h-20 flex items-center justify-center shadow-md">
                        <i className="fas fa-tasks fa-lg"></i>
                    </span>
                    <span className="h-12">{t('process.step3.title')}</span>
                    <p className="text-sm">{t('process.step3.description')}</p>
                </li>
                <li className="bg-white border border-purple-500 p-4 rounded-lg shadow text-center font-normal text-lg flex flex-col items-center space-y-4">
                    <span className="bg-purple-500 p-4 rounded-full text-white w-20 h-20 flex items-center justify-center shadow-md">
                        <i className="fas fa-check fa-lg"></i>
                    </span>
                    <span className="h-12">{t('process.step4.title')}</span>
                    <p className="text-sm">{t('process.step4.description')}</p>
                </li>
            </ol>
        </section>
    );
};

export default Process;
