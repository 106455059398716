import React from 'react';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();

    return (
        <section id="home" className="relative bg-cover bg-center text-white py-12 text-center mx-auto lg:py-36 sm:py-24" style={{ backgroundImage: "url('/images/splash_new.jpg')" }}>
            <div className="bg-black bg-opacity-50 absolute inset-0"></div>
            <div className="relative z-10">
                <h2 className="text-4xl font-semibold mb-4 md:text-3xl sm:text-2xl">{t('home.title')}</h2>
                <p className="text-lg font-light md:text-base sm:text-sm">{t('home.subtitle')}</p>
            </div>
        </section>
    );
}

export default Home;
