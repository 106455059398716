import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();

    return (
        <section id="about" className="bg-gray-100 py-16 content-container mx-auto">
            <h2 className="text-2xl text-center text-gray-800 mb-8">{t('about.title')}</h2>
            <p className="container mx-auto px-4 mb-12 text-center text-gray-800">
                {t('about.description')}
            </p>
            <div className="container mx-auto h-8"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 max-w-4xl mx-auto">
                <div className="text-center">
                    <img src="https://via.placeholder.com/200x200" alt={t('about.bianka')} className="w-64 mx-auto mb-6 rounded-full" />
                    <h3 className="text-2xl font-bold">{t('about.bianka')}</h3>
                    <p className="text-lg text-purple">{t('about.projectManager')}</p>
                </div>
                <div className="text-center">
                    <img src="https://via.placeholder.com/200x200" alt={t('about.balazs')} className="w-64 mx-auto mb-6 rounded-full" />
                    <h3 className="text-2xl font-bold">{t('about.balazs')}</h3>
                    <p className="text-lg text-purple">{t('about.itProfessional')}</p>
                </div>
            </div>
        </section>
    );
};

export default About;
