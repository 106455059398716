import React from 'react';
import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t } = useTranslation();

    return (
        <section id="services" className="py-12 content-container">
            <h2 className="text-2xl text-center text-purple mb-8">{t('services.title')}</h2>
            <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 gap-8">
                {/* Consultation */}
                <div className="bg-gray-100 p-4 rounded-lg shadow">
                    <img src="/images/consultation.jpg"
                         alt={t('services.consultation.alt')} className="w-full h-48 object-cover rounded-t-lg mb-4"/>
                    <div className="text-center">
                        <h3 className="text-xl text-purple mb-4">{t('services.consultation.title')}</h3>
                        <p>{t('services.consultation.description')}</p>
                    </div>
                </div>
                {/* Tender Management */}
                <div className="bg-gray-100 p-4 rounded-lg shadow">
                    <img src="/images/tender.jpg"
                         alt={t('services.tender_management.alt')} className="w-full h-48 object-cover rounded-t-lg mb-4"/>
                    <div className="text-center">
                        <h3 className="text-xl text-purple mb-4">{t('services.tender_management.title')}</h3>
                        <p>{t('services.tender_management.description')}</p>
                    </div>
                </div>
                {/* Implementation Oversight */}
                <div className="bg-gray-100 p-4 rounded-lg shadow">
                    <img src="/images/oversight.jpg"
                         alt={t('services.implementation_oversight.alt')} className="w-full h-48 object-cover rounded-t-lg mb-4"/>
                    <div className="text-center">
                        <h3 className="text-xl text-purple mb-4">{t('services.implementation_oversight.title')}</h3>
                        <p>{t('services.implementation_oversight.description')}</p>
                    </div>
                </div>
                {/* Project Evaluation */}
                <div className="bg-gray-100 p-4 rounded-lg shadow">
                    <img
                        src="/images/signoff.jpg"
                        alt={t('services.project_evaluation.alt')} className="w-full h-48 object-cover rounded-t-lg mb-4"/>
                    <div className="text-center">
                        <h3 className="text-xl text-purple mb-4">{t('services.project_evaluation.title')}</h3>
                        <p>{t('services.project_evaluation.description')}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
