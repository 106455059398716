import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Loader from "react-loader-spinner";

const Contact = () => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);

    const sendFormData = async (formData) => {
        try {
            const response = await fetch('https://us-central1-devidea-hu.cloudfunctions.net/saveFormData', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Error submitting form data');
            }

            return response;
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = { name, email, message };
        setIsLoading(true);
        try {
            const response = await sendFormData(formData);

            if (response) {
                setFormSubmitted(true);
            } else {
                // Handle any error that occurred during form submission
                alert('Error submitting form data. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const renderForm = () => (
        <div>
            <h2 className="text-2xl text-center text-gray-800 mb-8">{t('contact.title')}</h2>
            <p className="container mx-auto px-4 mb-8 text-center text-gray-800">
                {t('contact.description')}
            </p>
            <form ref={formRef} onSubmit={handleSubmit} className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-4 max-w-6xl bg-white p-8 rounded-lg shadow-md">
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder={t('contact.yourName')} className="border border-gray-300 px-3 py-2 w-full rounded" />
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t('contact.yourEmail')} className="border border-gray-300 px-3 py-2 w-full rounded" />
                <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder={t('contact.yourMessage')} className="border border-gray-300 px-3 py-2 w-full col-span-full resize-none h-36 rounded"></textarea>
                {isLoading ? (
                    <div className="col-span-full self-center mt-4 mx-auto">
                        <Loader.TailSpin type="ThreeDots" color="#333" height={40} width={40} />
                    </div>
                ) : (
                    <button
                        type="submit"
                        className="bg-gray-800 text-white px-4 py-2 font-semibold text-lg col-span-full self-center mt-4 rounded"
                    >
                        {t('contact.submit')}
                    </button>
                )}
            </form>
        </div>
    );

    const renderThankYou = () => (
        <div className="container mx-auto px-4 text-center">
            <i className="fas fa-envelope fa-6x text-gray-800 mb-4" ></i>
            <h3 className="text-xl text-gray-800 mb-4">{t('contact.thankYouTitle')}</h3>
            <p className="text-gray-800">{t('contact.thankYouMessage')}</p>
        </div>
    );

    return (
        <section id="contact" className="bg-gray-100 py-16 content-container">
            {formSubmitted ? renderThankYou() : renderForm()}
        </section>
    );
};

export default Contact;
