import React from 'react';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import Process from './components/Process';
import About from './components/About';
import References from './components/References';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
      <div className="font-sans text-gray-800 bg-gray-100">
        <Header />
        <Home />
        <Services />
        <Process />
        <About />
        <References />
        <Contact />
        <Footer />
      </div>
  );
}

export default App;
