import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { t } = useTranslation();
    const [isMenuHidden, setIsMenuHidden] = useState(true);

    useEffect(() => {
        const menuLinks = document.querySelectorAll('#menu a');

        const handleMenuLinkClick = () => {
            if (isMenuHidden) return;
            setIsMenuHidden(true);
        };

        menuLinks.forEach(link => {
            link.addEventListener('click', handleMenuLinkClick);
        });

        return () => {
            menuLinks.forEach(link => {
                link.removeEventListener('click', handleMenuLinkClick);
            });
        };
    }, [isMenuHidden]);

    const toggleMenu = () => {
        setIsMenuHidden(!isMenuHidden);
    };

    return (
        <header className="bg-purple-900 py-4">
            <nav className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between items-center w-full max-w-screen-lg mx-auto">
                    <div className="text-xl font-semibold mb-2 md:mb-0">
                        <h1 className="text-white">devIdea</h1>
                    </div>
                    <button id="menuToggle" className="md:hidden text-white p-2" aria-label="toggle menu"
                            onClick={toggleMenu}>
                        <i className="fas fa-bars"></i>
                    </button>

                    <ul id="menu"
                        className={`${
                            isMenuHidden ? 'hidden' : ''
                        } md:flex space-x-6 w-full md:w-auto mt-2 md:mt-0 md:static bg-purple-900`}
                    >
                        <li className="ml-6 md:ml-0 p-1 md:p-0">
                            <a href="#services" className="block py-2 px-4 md:py-0 md:px-0 text-white hover-purple">{t('header.services')}</a>
                        </li>
                        <li className="p-1 md:p-0">
                            <a href="#process" className="block py-2 px-4 md:py-0 md:px-0 text-white hover-purple">{t('header.process')}</a>
                        </li>
                        <li className="p-1 md:p-0">
                            <a href="#about" className="block py-2 px-4 md:py-0 md:px-0 text-white hover-purple">{t('header.about')}</a>
                        </li>
                        <li className="p-1 md:p-0">
                            <a href="#references" className="block py-2 px-4 md:py-0 md:px-0 text-white hover-purple">{t('header.references')}</a>
                        </li>
                        <li className="p-1 md:p-0">
                            <a href="#contact" className="block py-2 px-4 md:py-0 md:px-0 text-white hover-purple">{t('header.contact')}</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;
